<template>
    <div class="bodylist">
        <div class="tname">
        <p><img style="margin-left:20px" src="../../assets/image/logo.png" /> <span style="font-size:36px;margin-left:10px;font-weight: 800;">中联燃气</span> <img style="margin-left:50%" src="../../assets/image/logo_r.png"></p>
        </div>
        <div class="bgcontent">
            
           <div class="showcontent">
              <div class="title">
                  <p class="nav"><span style="margin-left:35px;font-size: 22px;font-weight: 800;color: #0D254C;">报装进度</span></p>
              </div>
              <div class="search">
                    <el-form ref="search" :inline="true" :model="search" size="small" >
                        <el-form-item label="" class="lab">
                            <el-input v-model="search.searchText" placeholder="个人/法人身份证号" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="" class="lab">
                            <el-button type="primary" @click="getList(1)">查询</el-button>
                        </el-form-item>
                    </el-form>
              </div>
              <div class="table">
                    <el-tabs v-model="activeName" @tab-click="handleClick" >
                        <el-tab-pane label="已申报" name="0"></el-tab-pane>
                        <el-tab-pane label="受理中" name="1"></el-tab-pane>
                        <el-tab-pane label="已取消" name="2"></el-tab-pane>
                        <el-tab-pane label="已完结" name="3"></el-tab-pane>
                    </el-tabs>
                     <el-table v-if="tableData.length>0" ref="tableData" :data="tableData" style="width: 100%;margin: 15px 0;" border  
                         
                        :stripe="true"
                    >
                    <el-table-column
                            prop="applyCode"
                            label="申请单号"
                            align="center"
                            show-overflow-tooltip
                            >
                            </el-table-column>
                        <el-table-column
                        prop="userName"
                        label="申请人"
                        align="center"
                        show-overflow-tooltip
                        
                        >
                        </el-table-column>
                        <el-table-column
                        prop="typeName"
                        label="用气类型"
                        align="center"
                        show-overflow-tooltip
                        
                        >
                        </el-table-column>
                        <el-table-column
                        prop="tel"
                        label="联系人电话"
                        align="center"
                        show-overflow-tooltip
                        
                        >
                        </el-table-column>
                        <el-table-column
                        prop="orderCode"
                        label="操作"
                        align="left"
                        show-overflow-tooltip
                        >
                        <template slot-scope="{ row }">
                        <el-button size="small" plain  @click="showImg(row)" >查看情况</el-button>
                        </template>
                        </el-table-column>
                     </el-table>
                     <div class="empty">
                         <div><img src="../../assets/indexpc/empty.png"/></div>
                         <div class="emptyTitle">暂无已申报事项</div>
                     </div>
              </div>
              
           </div>
        </div>
       
    </div>
</template>
<script>
import {bzApplyWeChat_page} from '../../RequestPort/pc/pc.js'
 export default {
    name: 'home',
    data() {
    return {
      search:{current:1,size:100,status:0},
      activeName:'0',
      tableData:[]
      }
      },
     methods: {
      goPath(path){
          this.$router.push({ path: path, query: {} })
      },
      getList(page){
          if(page){
              this.search.current = 1;
          }
          bzApplyWeChat_page(this.search).then(res=>{
              this.tableData = res.data.records
          })
      },
      handleClick(){
          this.search.status = this.activeName;
          this.getList(1)
      },
      showImg(item){
          this.$router.push({ path: '/descpc', query: {id:item.id} })
      }
    },
    mounted(){
        this.getList();
    }
 }
</script>

<style  scoped lang="scss">
.bodylist{
    margin: 0px 15%;
}
.tname {
  font-size: 0.5rem;
  text-align: center;
   margin-top:15px;
  p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 0.7rem;
    width: 100%;
    margin: 0 auto;
    
  }
}
.bgcontent{background-image: url('../../assets/indexpc/indexbg.png');background-size: 100% 100%;width: 100%;height: 100%;display: block;}

.showcontent{
    background-color: #fff;
    height: 100%;
    margin: 30px;
    top:15px;
    position: relative;
    }
.nav{border-left: 4px solid #2C90FF;position: relative;top:27px}
.search{
    top:50px;position: relative;padding-left: 35px;
}
.table{position: relative;top:40px;margin-left:35px}
.empty{text-align: center;top:132px;position: relative;}
.emptyTitle{font-size: 18px;
font-weight: 500;
color: #989797;}
</style>