import axios from '@/common/js/request'
//列表
export function bzApplyWeChat_page(params){
    const bzApplyWeChat_page = axios({
        url: '/bzApplyWeChat/page',
        method: 'get',
        params
    })
    return bzApplyWeChat_page
}
export function bzApplyWeChat_getDetail(params){
    const bzApplyWeChat_getDetail = axios({
        url: '/bzApplyWeChat/getDetail',
        method: 'get',
        params
    })
    return bzApplyWeChat_getDetail
}
export function bzApplyWeChat_save(params){
    const bzApplyWeChat_save = axios({
        url: '/bzApplyWeChat/save',
        method: 'post',
        data:params
    })
    return bzApplyWeChat_save
}

export function uploadImg(data){
    const uploadImg = axios({
        url: '/aliYun/uploadGasPatrolImgToAliYunOSS',
        method: 'post',
        data
    })
    return uploadImg
}